<template>
  <q-page-container>
    <q-page class="flex flex-center">
      <div id="q-app" style="min-height: 94vh;">
        <div class="q-pa-md">
          <br>
          <br>
          <q-virtual-scroll type="table" style="max-height: 88vh" :virtual-scroll-item-size="48" :virtual-scroll-sticky-size-start="48"
            :virtual-scroll-sticky-size-end="32" :items="boardData" @virtual-scroll="scroll">
            <template v-slot:before>
              <thead class="thead-sticky text-left">
                <tr>
                  <th width="15px">Place</th>
                  <th v-for="col in dataType" :key="'1--' + col.name" class="text-center">
                    {{ col.name }}
                  </th>
                </tr>
              </thead>
            </template>

            <template v-slot="{ item: row, index }">
              <tr :key="index">
                <td>#{{ index+1 }}</td>
                <td v-for="col in dataType" :key="index + '-' + col.name" class="text-center">
                  <span v-if="col.name == 'Name'">
                    <router-link :to="`/player/${row.id}`" >{{ row[col.prop] }}</router-link>
                  </span>
                  <span v-else>
                    {{ row[col.prop] }}
                  </span>
                </td>
              </tr>
            </template>
          </q-virtual-scroll>
        </div>
      </div>
      <q-page-sticky expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <img src="../../public/images/timer.png">
          <q-toolbar-title>
            <template v-if='querySeason.value == "Live"'>
              <template v-if='querySelection == "fastest"'>
                Fastest Times ({{ queryMap }})
              </template>
              <template v-else-if='querySelection == "score"'>
                Top Scores ({{ queryMap }})
              </template>
              <template v-else-if='querySelection == "mastery"'>
                Highest Mastery Stars
              </template>
              <template v-else-if='querySelection == "onslaught"'>
                Highest Onslaught Floors
              </template>
              <template v-else-if='querySelection == "survival"'>
                Highest Survival Waves
              </template>
              <template v-else-if='querySelection == "power"'>
                Most Powerful
              </template>
              <template v-else-if='querySelection == "trade"'>
                High Value Traders
              </template>
            </template>
            <template v-else>
              {{ querySeason.label }}
            </template>
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
    </q-page>
  </q-page-container>
</template>

<style>
a {
  text-decoration: none;
  color: var(--q-primary);
}
</style>

<script>
import { mapState } from 'vuex';
import store from '../js/store';

export default {
  name: 'Main',
  data() {
    return {
      dataType: [ 
        { name: 'Name', prop: 'name' },
        { name: 'Time', prop: 'duration' },
        { name: 'Date', prop: 'moment' },
        { name: 'Platform', prop: 'platform' },
      ],
      fastestData: [ 
        { name: 'Name', prop: 'name' },
        { name: 'Time', prop: 'duration' },
        { name: 'Date', prop: 'moment' },
        { name: 'Platform', prop: 'platform' },
      ],
      scoreData: [ 
        { name: 'Name', prop: 'name' },
        { name: 'Score', prop: 'score' },
        { name: 'Date', prop: 'moment' },
        { name: 'Platform', prop: 'platform' },
      ],
      masteryData: [ 
        { name: 'Name', prop: 'name' },
        { name: 'Stars', prop: 'stars' },
        { name: 'Date', prop: 'moment' },
        { name: 'Platform', prop: 'platform' },
      ],
      onslaughtData: [ 
        { name: 'Name', prop: 'name' },
        { name: 'Floor', prop: 'floor' },
        { name: 'Date', prop: 'moment' },
        { name: 'Platform', prop: 'platform' },
      ],
      survivalData: [ 
        { name: 'Name', prop: 'name' },
        //{ name: 'Map', prop: 'map' },
        { name: 'Wave', prop: 'wave' },
        { name: 'Date', prop: 'moment' },
        { name: 'Platform', prop: 'platform' },
      ],
      powerData: [ 
        { name: 'Name', prop: 'name' },
        { name: 'Ancient Power', prop: 'prestige' },
        { name: 'Ascension', prop: 'accountlevel' },
        { name: 'Date', prop: 'moment' },
        { name: 'Platform', prop: 'platform' },
      ],
      tradeData: [ 
        { name: 'Name', prop: 'name' },
        { name: 'Trades', prop: 'trades' },
        { name: 'Date', prop: 'moment' },
        { name: 'Platform', prop: 'platform' },
      ],
    }
  },
  computed: {
    ...mapState(['boardData', 'queryAmt', 'querySeason', 'querySelection',  'queryMap', 'queryDifficulty']),
  },
  methods: {
    scroll(details) {
      if (details.index > this.queryAmt - 35) {
        store.dispatch('storeQueryAmt');
      }
    },
    headerUpdater() {
      if (this.querySeason.value.includes('mastery') || this.querySelection == 'mastery') {
        this.dataType = this.masteryData;
      } else if (this.querySelection == 'score') {
        this.dataType = this.scoreData;
      } else if (this.querySelection == 'fastest') {
        this.dataType = this.fastestData;
      } else if (this.querySelection == 'onslaught') {
        this.dataType = this.onslaughtData;
      } else if (this.querySelection == 'survival') {
        this.dataType = this.survivalData;
      } else if (this.querySelection == 'power') {
        this.dataType = this.powerData;
      } else if (this.querySelection == 'trade') {
        this.dataType = this.tradeData;
      }
    },
  },
  watch: {
    querySeason() {
      this.headerUpdater();
    },
    querySelection() {
      this.headerUpdater();
    }
  },
  mounted() {
    this.headerUpdater();
  }
}
</script>
