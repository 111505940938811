<template>
  <q-page-container>
    <q-page class="flex flex-center">
      <div id="q-app" style="min-height: 94vh;">
        <br>
        <br>
        <q-list padding style="max-width: 1280px">
          <q-item-label header>Information</q-item-label>
          <q-item>
            <q-item-section avatar top>
              <q-avatar icon="help" color="blue" text-color="white"></q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Requirements</q-item-label>
              <q-item-label caption>Fastest: Achieved victory, while playing solo, with no restart from wave, and within 2 hours.</q-item-label>
              <q-item-label caption>Score: Achieved victory, while playing solo, with no restart from wave.</q-item-label>
              <q-item-label caption>Mastery: Acquired at least 1 star.</q-item-label>
              <q-item-label caption>Onslaught: Player must have won 1 floor.</q-item-label>
              <q-item-label caption>Survival: Player must have won 1 wave.</q-item-label>
              <q-item-label caption>Power: Acquired at least 10 ascension levels.</q-item-label>
              <q-item-label caption>Trade: Traded 10 times with each trade worth at least 10,000,000 in value. Multiple trades with the same other player only counts as 1 trade if done on the same day(UTC).</q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section avatar top>
              <q-avatar icon="help" color="blue" text-color="white"></q-avatar>
            </q-item-section>
              <q-item-section>
              <q-item-label lines="1">Time to Appear</q-item-label>
              <q-item-label caption>Due to metrics having to go through multiple systems for retrieval it can take upwards of 2 hours for your addition/update to appear. Though it tends to display it quicker than that.</q-item-label>
              <q-item-label caption>Note: The Trade leaderboard updates daily, not hourly like the others.</q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section avatar top>
              <q-avatar icon="help" color="blue" text-color="white"></q-avatar>
            </q-item-section>
              <q-item-section>
              <q-item-label lines="1">Placement Updates</q-item-label>
              <q-item-label caption>New scores will appear with an automatic display ranking for the top 500. The ranking comparison on the player page however only updates daily.</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <q-separator spaced></q-separator>
        <q-item-label header>Help</q-item-label>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="blue" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">How do I see other modes, difficulties, or enable light mode?</q-item-label>
            <q-item-label caption>Click the stack icon (<q-icon class="material-icons">menu</q-icon>) in the upper left!</q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="blue" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">I think something's wrong!</q-item-label>
            <q-item-label caption>Please contact us on <a href="https://discord.com/invite/dd2">Discord</a> or through <a href="https://chromatic.zendesk.com/hc/en-us/requests/new">Zendesk</a> support. Provide as much detail as possible.</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator spaced></q-separator>
        <q-item-label header>Other</q-item-label>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="blue" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">Player Names</q-item-label>
            <q-item-label caption>Names listed on the leaderboard are what they were at the time an entry first made its appearance. Names will update over time if a player continues to get new or better leaderboard times, scores, or anything that updates an entry.</q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="blue" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">Terminology</q-item-label>
            <q-item-label caption>WR: World Rank - How you compare to all of DD2's Players.</q-item-label>
            <q-item-label caption>PR: Platform Rank - How you compare to the DD2 Players of the platform you play on.</q-item-label>
          </q-item-section>
        </q-item>
      </div>
      <q-page-sticky expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <img src="../../public/images/information.png">
          <q-toolbar-title>
            General Information and Help
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
    </q-page>
  </q-page-container>
</template>

<script>
export default {
  name: 'Help',
}
</script>
