<template>
  <div style="height: 500px;">    
    <div class="row items-center" style="height: 100%;">
      <div class="col-3 ">
      </div>
      <div class="col-6 justify-center items-center content-center" >
          <p class="text-h3 text-center">We'll be back soon!</p>
      
          <p class="text-body1 text-center">
            We're very sorry for the inconvinience but we are performing maintenance.
          </p>     
        </div>
        <div class="col-3">
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
}
</script>
