import { createRouter, createWebHistory } from 'vue-router';
import Main from '../components/Main.vue';
import Player from '../components/Player.vue';
import PlayerFinder from '../components/PlayerFinder.vue';
import PVLookup from '../components/PVLookup.vue';
import Help from '../components/Help.vue';
import { getPlayerId } from './query';

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Main',
    component: Main,
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
  },
  {
    path: '/player/:id',
    name: 'Player',
    component: Player,
    meta: {
      title: 'Player'
    },
    props: true
  },
  {
    path: '/finder',
    name: 'Player Finder',
    component: PlayerFinder,
  },
  {
    path: '/pvlookup/:pvid',
    name: 'PVLookup',
    component: PVLookup,
    meta: {
      title: 'Lookup'
    },
    beforeEnter: async (to, from, next) => {
      if (to.params.pvid && to.params.pvid.length >= 8) {
        await getPlayerId(to.params.pvid)
          .then((response) => {
            next({ name: 'Player', params: { id: response }});
          })
          .catch(() => {
            //
            next(Main);
          })
      } else {
        next(Main);
      }
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
