import { createApp } from 'vue'
import App from './App.vue'
import Maintenance from './components/Maintenance.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import store from './js/store';
import router from './js/router';

const in_maintenance = false;

if(in_maintenance) {
    createApp(Maintenance).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app');
} else {
    createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app');
}
