import { createStore } from 'vuex';

const store = createStore({
  state: {
    availableBoards: [],
    cachedResults: new Map(),
    boardData: [{ id: 0, name: 'Please select a leaderboard to view!'}],
    playerData: { id: 0, name: 'Attempting to find player data...' },
    playerScores: [{ wr: 'NR', pr: 'NR', type: 'No scores found for this player.', map: '', diff: '', score: '', moment: '' }],
    playerTimes: [{ wr: 'NR', pr: 'NR', type: 'No times found for this player.', map: '', diff: '', duration: '', moment: '' }],
    playerOther: [],
    playerNames: [{id: 0, name: "Awaiting a player search...", lastseen: "-"}],
    queryAmt: 100,
    queryType: 'EndGame_Defense',
    querySelection: 'fastest',
    queryDifficulty: '1',
    queryMap: 'Dragonfall Bazaar',
    queryPlatform: 'ALL',
    querySeason: { label: "Live", value: "Live" },
    mapOptions: ["Altar of the Athame","Assault on Throne Room","Bastille Master","Buried Bastille","Chrome Enemies","Crumbled Bulwark","Crystal Mine","Dark Awakening",
    "Dawn of the Blood Moon","Dragonfall Bazaar","Dragonfall Sewers","Drakenfrost Keep","Drakenfrost Resort","Forest Biome","Forest Crossroads","Forest Poachers",
    "Forgotten Ruins","Greystone Plaza","Griblock's Horde","Harbinger's Warship","Jester's Revenge","Kobold Bling King","Lava Caverns","Liferoot Forest","Little-Horn Valley",
    "Malthius Incursion","Molten Citadel","Nimbus Reach","Plunderer's Paradise","Power Surge","Ramparts Siege","Return of Maldonis","Revenge of the Yeti",
    "Siphon Site D","Spectral Assault","Temple of the Necrotic","The Dead Road","The Demon's Lair","The Gates of Dragonfall","The Jacked Sparrow","The Ramparts",
    "The Throne Room","The Wildest West","The Wyvern Den","Tornado Canyon","Tornado Highlands","Unholy Catacombs","Wild Westival","Wyvern Enthusiast","Wyvern's Den"]
  },
  mutations: {
    updateAvailableBoards (state, value) {
      state.availableBoards = value;
    },
    updateCachedResults (state, value) {
      // Skipping caching for now.
      // state.cachedResults.delete(value.board);
      // state.cachedResults.set(value.board, value.results);
      if (!value.board.includes('player')) {
        state.boardData = value.results;
      } else {
        state[value.board] = value.results;
      }
    },
    updateQueryAmt (state) {
      if (state.queryAmt <= 400) {
        state.queryAmt = state.queryAmt + 100;
      }
    },
    updateQueryOption (state, option) {
      state[`query` + option.name] = option.value;
      if (option.name === 'Type' || option.name === 'Difficulty' ) {
        if (!state.availableBoards['time_' + state.queryType + '_' + state.queryDifficulty].map.includes(state.queryMap)) {
          state.queryMap = state.availableBoards['time_' + state.queryType + '_' + state.queryDifficulty].map[0];
        }
      }
    },
    emptyLeaderboard (state) {
      state.queryAmt = 100;
      state.boardData = [{ id: 0, name: 'Leaderboard Loading...'}];
    },
  },
  actions: {
    storeAvailableBoards ({ commit }, payload) {
      commit('updateAvailableBoards', payload);
    },
    storeCachedResults ({ commit }, payload) {
      commit('updateCachedResults', payload);
    },
    storeQueryAmt ({ commit }) {
      commit('updateQueryAmt');
    },
    storeQueryOption ({ commit }, payload) {
      commit('updateQueryOption', payload)
    },
    clearLeaderboard ({ commit }) {
      commit('emptyLeaderboard')
    }
  },
  getters: {
    getCuratedMapList: (state) => () => {
      if (state.availableBoards.length > 0) {
        if (state.querySeason != 'Live') {
          return state.mapOptions;
        } else {
          return state.availableBoards['time_' + state.queryType + '_' + state.queryDifficulty];
        }
      }
      return ['Dragonfall Bazaar'];
    }
  },
})

export default store;
