<template>
  <q-page class="flex flex-center">
    <div id="q-app" style="min-height: 94vh;">
      Looking up Player's Leaderboard
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'PVLookup',
}
</script>
