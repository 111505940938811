<template>
  <link rel="shortcut icon" type="image/png" href="/images/favicon.png"/>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Options" icon="menu"/>
        <q-toolbar-title>
          DD2 Leaderboard <template v-if="$route.name != 'Main'"><router-link to="/"><q-icon class="material-icons" color="black">home</q-icon></router-link></template>
        </q-toolbar-title>
        <router-link to="/help"><q-icon class="material-icons" size="sm" color="black">help</q-icon></router-link>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" bordered class="drawer" :width="265">
      <q-list>
        <q-item-label header>Leaderboard Options <q-icon id="generateLink" class="material-icons" size="xs" @click="generateLink()">content_copy</q-icon></q-item-label>
        <q-item>
          <q-item-section>
            <q-item-label>Selection</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense :options="optsSelection" type="radio" v-model="lbSelection" inline @update:model-value="changeQueryOption('Selection', lbSelection)"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Game Type</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense :options="optsType" type="radio" v-model="lbType" inline @update:model-value="changeQueryOption('Type', lbType)"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Special game modes</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense :options="optsSpecialModes" type="radio" v-model="lbSelection" inline @update:model-value="changeQueryOption('Selection', lbSelection)"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Difficulty</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense :options="optsDifficulty" type="radio" v-model="lbDifficulty" inline @update:model-value="changeQueryOption('Difficulty', lbDifficulty)"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Map</q-item-label>
            <div class="q-gutter-sm">
              <q-select outlined v-model="lbMapSelected" :options="getCuratedMapList" @update:model-value="changeQueryOption('Map', lbMapSelected)"></q-select>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Platform</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense :options="optsPlatform" type="radio" v-model="lbPlatform" inline @update:model-value="changeQueryOption('Platform', lbPlatform)"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Event / Special</q-item-label>
            <div class="q-gutter-sm">
              <q-select outlined :options="optsSeason" v-model="lbSeason" @update:model-value="changeQueryOption('Season', lbSeason)"></q-select>
            </div>
          </q-item-section>
        </q-item>
        <br>
        <q-separator></q-separator>
        <br>
        <q-btn to='/finder' class="full-width" color="primary" icon="search" label="Player Finder"></q-btn>
        <br>
        <br>
        <q-separator></q-separator>
        <q-item-label header>Display Options</q-item-label>
        <q-item>
          <q-item-section>
            <q-item-label>Light Mode</q-item-label>
            <div class="q-gutter-sm">
              <q-toggle size="s" v-model="optionLightMode" inline @update:model-value="toggleLightMode()"></q-toggle>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
    <router-view />
  </q-layout>
</template>

<style>
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #8da1b4;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5b88db;
}

#generateLink {
  cursor: pointer;
}
</style>

<script>
import { ref } from 'vue';
import { mapState } from 'vuex';
import { getAvailableBoards, getQueryResults } from './js/query';
import store from './js/store';
import { Dark, Notify } from 'quasar';

export default {
  name: 'App',
  setup () {
    const optionLightMode = ref(localStorage.getItem('optionLightMode')==='true');

    const changeQueryOption = (name, value) => {
      if (name === 'Season' && value.value === 'historical_ascension') {
        window.open('../ascension_historical.html', '_blank');
      } else {
        store.dispatch('storeQueryOption', { name, value });
        store.dispatch('clearLeaderboard');
        getQueryResults(false);
      }
    };

    const toggleLightMode = () => {
      Dark.set(!optionLightMode.value);
      window.localStorage.setItem('optionLightMode', optionLightMode.value);
    };

    return {
      leftDrawerOpen: ref(false),
      optsSelection: [
        { label: 'Fastest', value: 'fastest' },
        { label: 'Score', value: 'score' }, 
        { label: 'Mastery', value: 'mastery'},
        { label: 'Power', value: 'power' },
        { label: 'Trade', value: 'trade' }
        //{ label: 'Player', value: 'player', disable: true}
        ],
      optsSpecialModes : [
        { label: 'Onslaught', value: 'onslaught' },
        { label: 'Survival', value: 'survival' },
      ],
      optsType: [
        { label: 'Expeditions', value: 'EndGame_Defense' }, 
        { label: 'Incursions', value: 'EndGame_Incursion' },
        { label: 'Mastery', value: 'Challenge' },
        ],
      optsDifficulty: [
        { label: '1', value: '1' }, 
        { label: '2', value: '2' },
        { label: '3', value: '3' }, 
        { label: '4', value: '4' },
        { label: '5', value: '5' }, 
        { label: '6', value: '6' },
        { label: '7', value: '7' }, 
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10'},
        ],
      optsPlatform: [
        { label: 'All', value: 'ALL' }, 
        { label: 'Steam', value: 'PC' },
        { label: 'PlayStation', value: 'PS4' }, 
        { label: 'Xbox', value: 'XB1' },
        ],
      optsSeason: [
        { label: 'Live', value: 'Live' },
        { label: 'Mastery (735 Archive)', value: 'archive_mastery' },
        { label: 'Ascension (Historical)', value: 'historical_ascension' }
        ],
      lbType: ref(store.state.queryType),
      lbSelection: ref(store.state.querySelection),
      lbDifficulty: ref(store.state.queryDifficulty),
      lbMapSelected: ref(store.state.queryMap),
      lbPlatform: ref(store.state.queryPlatform),
      lbSeason: ref(store.state.querySeason),
      optionLightMode,
      changeQueryOption,
      toggleLightMode
    }
  },
  computed: {
    ...mapState(['queryAmt']),
    getCuratedMapList() {
      if (Object.keys(store.state.availableBoards).length > 0) {
        if (store.state.querySeason.value != 'Live') {
          return store.state.mapOptions;
        } else {
          return store.state.availableBoards['time_' + store.state.queryType + '_' + store.state.queryDifficulty].map;
        }
      }
      return ['Dragonfall Bazaar'];
    }
  },
  watch: {
    queryAmt() {
      if (this.queryAmt > 100) {
        getQueryResults(false);
      }
    },
    getCuratedMapList() {
      this.lbMapSelected = store.state.queryMap;
    }
  },
  methods: {
    async getBoards() {
      const urlParams = new URLSearchParams(window.location.search);
      if ([...urlParams].length) {
        getAvailableBoards().then(() => {
          if (['Fastest', 'Score'].includes(urlParams.get('selection'))) {
            this.lbType = this.optsType.find(i => i.label === urlParams.get('type')).value;
            store.dispatch('storeQueryOption', { name: 'Type', value: this.lbType });

            this.lbDifficulty = urlParams.get('difficulty');
            store.dispatch('storeQueryOption', { name: 'Difficulty', value: this.lbDifficulty });

            this.lbMapSelected = urlParams.get('map').replaceAll('_', ' ');
            store.dispatch('storeQueryOption', { name: 'Map', value: this.lbMapSelected });
          }

          this.lbSelection = this.optsSelection.find(i => i.label === urlParams.get('selection')).value;
          store.dispatch('storeQueryOption', { name: 'Selection', value: this.lbSelection });

          this.lbPlatform = this.optsPlatform.find(i => i.label === urlParams.get('platform')).value;
          store.dispatch('storeQueryOption', { name: 'Platform', value: this.lbPlatform });

          getQueryResults(false);
        });
      } else {
        getQueryResults(true);
        getAvailableBoards();
      }
    },
    generateLink() {
      let link = window.location.origin + '?selection=' + this.optsSelection.find(i => i.value === this.lbSelection).label;

      if (['fastest', 'score'].includes(this.lbSelection)) {
        const mapUpdate = this.lbMapSelected.replaceAll(' ', '_');
        link = link + '&type=' + this.optsType.find(i => i.value === this.lbType).label + '&difficulty=' + this.lbDifficulty + '&map=' + mapUpdate;
      }

      link = link + '&platform=' + this.optsPlatform.find(i => i.value === this.lbPlatform).label;
      navigator.clipboard.writeText(link);
      Notify.create({ message: 'Leaderboard Link Copied!', color: 'positive', position: 'top-left'});
    }
  },
  created() {
    this.getBoards();
    this.toggleLightMode();
    document.title = `Dungeon Defenders 2 Leaderboards`;
  }
}
</script>
