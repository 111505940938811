import axios from "axios";
import store from "./store";

function calculateWeeksBetween(date1, date2) { // SO: 22859704
  const oneWeek = 1000 * 60 * 60 * 24 * 7;
  const date1_ms = date1.getTime();
  const date2_ms = date2.getTime();
  const difference_ms = Math.abs(date1_ms - date2_ms);
  return Math.floor(difference_ms / oneWeek);
}

const getAvailableBoards = async () => {
  const params = {
    query: 'boards'
  }

  await axios.get('https://dd2worker.dundef.workers.dev', { params }, { timeout: 10000 })
    .then((response) => {
      let reduced = response.data.result.reduce((a,b)=>{
        if(!a[b.type]){
          a[b.type] = {
            type: b.type,
            map: [b.map]
          }
        }
        else{
          a[b.type].map.push(b.map);
        }
        return a;
      }, []);
      store.dispatch('storeAvailableBoards', reduced);
    });

  return true;
}

function timeConverter(time) {
  let t = time.split(/:|\./),
      s = ['h', 'm', 's', 'ms'],
      i = 0;
  for( ; i < t.length; i++ ) {
      t[i] = parseFloat(t[i]) == 0 ? '' : parseFloat(t[i]) + s[i + s.length - t.length];
      t[i] = t[i].length > 0 && t[i].length < 3 ? '0' + t[i] : t[i];
      t[i] = t[i].length == 4 ? '0' + t[i] : t[i];
  }
  return t.join(' ');
}

const getQueryResults = (first = false) => {
  
  const params = {
    query: store.state.querySelection,
    amt: store.state.queryAmt,
    type: store.state.queryType,
    diff: store.state.queryDifficulty,
    map: store.state.queryMap.replaceAll(' ', '_').replaceAll(/'/g, "5"),
    plat: store.state.queryPlatform,
    s: store.state.querySeason.value
  }

  if (store.state.availableBoards['time_' + params.type + '_' + params.diff] != null && params.type != null && params.diff != null && params.map != null) {
    // This will only work for fastest right now.
    if (!store.state.availableBoards['time_' + params.type + '_' + params.diff].map.includes(params.map.replaceAll('_', ' ').replaceAll('5', "'"))) {
      store.dispatch('storeCachedResults', { board: params.query + '_' + params.type + '_' + params.diff + '_' + params.map,
        results: [{ id: 0, name: 'No Leaderboard scores available with the current options selected.'}] });
      return params;
    }
  } else {
    if (!first)
      throw "Error: Client not ready for requests";
  }

  axios.get('https://dd2worker.dundef.workers.dev', { params }, { timeout: 10000 })
    .then((response) => {
      let queryData = response.data.result;
      const mainQueries = ['fastest', 'score', 'mastery', 'onslaught','survival', 'power', 'trade'];
      if (mainQueries.includes(params.query)) {
        queryData.forEach((row, index) => {
          if (!params.s.includes('archive') && params.query === 'fastest') {
            queryData[index].duration = timeConverter(new Date(queryData[index].duration * 1000).toISOString().slice(11, -1));
          }

          if (!params.s.includes('archive') && params.query === 'score') {
            queryData[index].score = queryData[index].score.toLocaleString();
          }

          queryData[index].moment = new Date(queryData[index].moment).toUTCString().split(' ').slice(1, 5).join(' ');

          switch(queryData[index].platform) {
            case 'PC':
              queryData[index].platform = 'Steam';
              break;
            case 'PS4':
              queryData[index].platform = 'PlayStation';
              break;
            case 'XB1':
              queryData[index].platform = 'Xbox';
              break;
          }
        })
      }
      return queryData;
    })
    .then((data) => {
      store.dispatch('storeCachedResults', { board: params.query + '_' + params.type + '_' + params.diff + '_' + params.map, results: data });
    });
  return params;
}

const getPlayerResults = (id) => {
  const params = {
    query: 'player',
    id: id
  }

  if (id == null) {
    throw "Error: Id not found.";
  } else if (id == 0) {
    store.dispatch('storeCachedResults', { board: 'player_0',
      results: [{ id: 0, Name: 'Player was not found and/or no completed scores were available for display.' }] });
  } else {
    axios.get('https://dd2worker.dundef.workers.dev', { params }, { timeout: 10000 })
      .then((response) => {
        if (response.data.result.id) {
          const queryData = response.data.result;

          queryData.scores.forEach((row, index) => {
            queryData.scores[index].score = queryData.scores[index].score.toLocaleString();
            queryData.scores[index].moment = new Date(queryData.scores[index].moment).toUTCString().split(' ').slice(1, 5).join(' ');
          });

          queryData.times.forEach((row, index) => {
            queryData.times[index].duration = timeConverter(new Date(queryData.times[index].duration * 1000).toISOString().slice(11, -1));
            queryData.times[index].moment = new Date(queryData.times[index].moment).toUTCString().split(' ').slice(1, 5).join(' ');
          });

          store.dispatch('storeCachedResults', { board: 'playerData', results: { id: queryData.id, name: queryData.name }});
          store.dispatch('storeCachedResults', { board: 'playerScores', results: queryData.scores });
          store.dispatch('storeCachedResults', { board: 'playerTimes', results: queryData.times });
          store.dispatch('storeCachedResults', { board: 'playerOther', results: queryData.other });
        } else {
          store.dispatch('storeCachedResults', { board: 'playerData', results: { id: params.id, name: 'No Player Information was found. Please refresh again later!' }});
        }
      })
      .catch(() => {
        //
      });
  }
  return true;
}

const getPlayerId = (pvid) => {
  const params = {
    query: 'pvlookup',
    pfid: pvid
  }
 
  if (pvid == null) {
    throw "Error: Lookup failed.";
  } else {
    return axios.get('https://dd2worker.dundef.workers.dev', { params }, { timeout: 10000 })
      .then((response) => {
        const queryData = response.data.result;
        return queryData;
      })
      .catch(() => {
        return "0";
      })
  }
}

const getPlayerNames = (name) => {
  const params = {
    query: 'playerfinder',
    name: name
  }
 
  if (name == null) {
    throw "Error: Invalid Player Name";
  } else {
    return axios.get('https://dd2worker.dundef.workers.dev', { params }, { timeout: 10000 })
      .then((response) => {
        const queryData = response.data.result;
        queryData.forEach((player) => {
          const date = new Date(player.updated);
          const twoWeeksAgo = new Date(Date.now()); //-12096e5

          if (date >= twoWeeksAgo) {
            player.lastseen = "Recently";
          } else if (player.id !== 0) {
            player.lastseen = calculateWeeksBetween(twoWeeksAgo, date) + " Weeks Ago";
          } else {
            player.lastseen = "-";
          }
        });
        store.dispatch('storeCachedResults', { board: 'playerNames', results: queryData });
      })
      .catch(() => {
        //
      })
  }
}

export {
  getAvailableBoards,
  getQueryResults,
  getPlayerResults,
  getPlayerId,
  getPlayerNames
}