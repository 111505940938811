<template>
  <q-page-container>
    <q-page class="flex flex-center">
      <div id="q-app" style="min-height: 94vh;">
        <template v-if="id != 0">
          <br>
          <br>
          <div class="q-pa-md row items-start q-gutter-md flex-center" align="center" style="width:98%">
            <q-card class="card-details" align="center">
              <q-card-section class="bg-primary text-white text-h6" >
                Top Onslaught Floor
              </q-card-section>
              <template v-if="playerDataMap.get('Onslaught')">
                <q-card-section>
                  {{ playerDataMap.get('Onslaught').data }}
                </q-card-section>
                <q-separator dark></q-separator>
                <q-card-section horizontal>
                  <q-card-section style="width:50%">
                    WR: {{ playerDataMap.get('Onslaught').worldrank }}
                  </q-card-section>
                  <q-separator vertical></q-separator>
                  <q-card-section style="width:50%">
                    PR: {{ playerDataMap.get('Onslaught').platrank }}
                  </q-card-section>
                </q-card-section>
              </template>
              <template v-else>
                <q-card-section>
                  &nbsp;
                </q-card-section>
                <q-separator dark></q-separator>
                <q-card-section>
                  &nbsp;
                </q-card-section>
              </template>
            </q-card>
            <q-card class="card-details" align="center">
              <q-card-section class="bg-primary text-white text-h6">
                Stars Collected
              </q-card-section>
              <template v-if="playerDataMap.get('Mastery')">
                <q-card-section>
                  {{ playerDataMap.get('Mastery').data }}
                </q-card-section>
                <q-separator dark></q-separator>
                <q-card-section horizontal>
                  <q-card-section style="width:50%">
                    WR: {{ playerDataMap.get('Mastery').worldrank }}
                  </q-card-section>
                  <q-separator vertical></q-separator>
                  <q-card-section style="width:50%">
                    PR: {{ playerDataMap.get('Mastery').platrank }}
                  </q-card-section>
                </q-card-section>
              </template>
              <template v-else>
                <q-card-section>
                  &nbsp;
                </q-card-section>
                <q-separator dark></q-separator>
                <q-card-section>
                  &nbsp;
                </q-card-section>
              </template>
            </q-card>
            <q-card class="card-details" align="center">
              <q-card-section class="bg-primary text-white text-h6">
                Power Gained
              </q-card-section>
              <template v-if="playerDataMap.get('Power') && playerDataMap.get('Ascension')">
                <q-card-section>
                  {{ playerDataMap.get('Power').data }}.{{ playerDataMap.get('Ascension').data }}
                </q-card-section>
                <q-separator dark></q-separator>
                <q-card-section horizontal>
                  <q-card-section style="width:50%">
                    WR: {{ playerDataMap.get('Power').worldrank }}
                  </q-card-section>
                  <q-separator vertical></q-separator>
                  <q-card-section style="width:50%">
                    PR: {{ playerDataMap.get('Power').platrank }}
                  </q-card-section>
                </q-card-section>
              </template>
              <template v-else>
                <q-card-section>
                  &nbsp;
                </q-card-section>
                <q-separator dark></q-separator>
                <q-card-section>
                  &nbsp;
                </q-card-section>
              </template>
            </q-card>
            <q-card class="card-details" align="center">
              <q-card-section class="bg-primary text-white text-h6">
                High Value Trades
              </q-card-section>
              <template v-if="playerDataMap.get('Trade')">
                <q-card-section>
                  {{ playerDataMap.get('Trade').data }}
                </q-card-section>
                <q-separator dark></q-separator>
                <q-card-section horizontal>
                  <q-card-section style="width:50%">
                    WR: {{ playerDataMap.get('Trade').worldrank }}
                  </q-card-section>
                  <q-separator vertical></q-separator>
                  <q-card-section style="width:50%">
                    PR: {{ playerDataMap.get('Trade').platrank }}
                  </q-card-section>
                </q-card-section>
              </template>
              <template v-else>
                <q-card-section>
                  &nbsp;
                </q-card-section>
                <q-separator dark></q-separator>
                <q-card-section>
                  &nbsp;
                </q-card-section>
              </template>
            </q-card>
          </div>
          <div class="q-pa-md">
            <q-table title="Fastest Times" dense 
              :rows="playerTimes" 
              :columns="timesColumns" 
              :rows-per-page-options=[10,25,50,100,0] 
              :loading="loadingData" 
              :pagination="initialPagination"
              :binary-state-sort=true>
            </q-table>
          </div>
          <div class="q-pa-md">
            <q-table title="Top Scores" dense 
              :rows="playerScores" 
              :columns="scoresColumns" 
              :rows-per-page-options=[10,25,50,100,0] 
              :loading="loadingData" 
              :pagination="initialPagination"
              :binary-state-sort=true>
            </q-table>
          </div>
        </template>
        <template v-else>
          <br>
          <br>
          <div class="q-pa-md q-gutter-sm">
            <q-banner rounded class="text-white">
              <div>No scores were found with the provided player information. Please see the <router-link to="/help">help page</router-link> for more information about requirements to appear.</div>
            </q-banner>
              <br>
            <q-banner rounded class="bg-grey-10 text-white">
              <div>This page will auto-redirect in 30 seconds to the home page. Click <q-btn class="bg-white text-primary" @click="manualRedirect();">Here</q-btn> to navigate there instantly.</div>
            </q-banner>
          </div>
        </template>
      </div>
      <q-page-sticky v-if="id != 0" expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <img src="/images/hero.png">
          <q-toolbar-title>
            {{ playerData.name }}
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
      <q-page-sticky v-else expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <q-toolbar-title>
            Player Not Found
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
    </q-page>
  </q-page-container>
</template>

<style>
img {
  max-width:32px;
}

.card-details {
  width: 100%;
  max-width: 250px;
}
</style>

<script>
import { mapState } from 'vuex';
import { getPlayerResults } from '../js/query';

export default {
  name: 'Player',
  props: {
    sessionTime: String,
    mapTime: String,
    id: {
      required: true,
    }
  },
  setup () {
    return {
      initialPagination: {
        sortBy: 'worldrank'
      },
      timesColumns: [
        { name: 'worldrank', label: 'WR', field: 'worldrank', align: 'left', sortable: true },
        { name: 'platrank', label: 'PR', field: 'platrank', align: 'left', sortable: true },
        { name: 'type', label: 'Type', field: 'type', align: 'left', sortable: true },
        { name: 'map', label: 'Map', field: 'map', align: 'left', sortable: true },
        { name: 'difficulty', label: 'Difficulty', field: 'diff', align: 'left', sortable: true },
        { name: 'time', label: 'Time', field: 'duration', align: 'left', sortable: true },
        { name: 'date', label: 'Date', field: 'moment', align: 'left', sortable: true },
      ],
      scoresColumns: [
        { name: 'worldrank', label: 'WR', field: 'worldrank', align: 'left', sortable: true },
        { name: 'platrank', label: 'PR', field: 'platrank', align: 'left', sortable: true },
        { name: 'type', label: 'Type', field: 'type', align: 'left', sortable: true },
        { name: 'map', label: 'Map', field: 'map', align: 'left', sortable: true },
        { name: 'difficulty', label: 'Difficulty', field: 'diff', align: 'left', sortable: true },
        { name: 'score', label: 'Score', field: 'score', align: 'left', sortable: true },
        { name: 'date', label: 'Date', field: 'moment', align: 'left', sortable: true },
      ],
      loadingData: true,
      playerDataMap: new Map(),
    };
  },
  computed: {
    ...mapState(['playerData', 'playerScores', 'playerTimes', 'playerOther']),
  },
  methods: {
    manualRedirect() {
      clearTimeout(this.redirectTimer);
      this.$router.push({ path: '/' });
    },
  },
  mounted() {
    if (this.id != 0) {
      getPlayerResults(this.id);
    } else {
      this.redirectTimer = setTimeout( () => this.$router.push({ path: '/' }), 30000);
    }
  },
  watch: {
    playerData() {
      this.loadingData = false;
    },
    playerOther() {
      if (this.playerOther.length > 0) {
        this.playerDataMap = new Map(this.playerOther.map(x => [x.board, x]));
      }
    }
  },
}
</script>
