<template>
  <q-page-container>
    <q-page class="flex flex-center">
      <div id="q-app" style="min-height: 94vh;">
        <div class="q-pa-md">
          <br>
          <br>
          <div class="q-pa-md row items-start" style="width:98%">
            <q-form @submit="findPlayers()" @reset="resetForm" class="q-gutter-md" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false">
              <q-input filled
                v-model="playerName"
                label="Player name"
                hint="Only letters, numbers, and spaces allowed. 3 Character Minimum."
                lazy-rules
                :rules="[ val => val && val.length > 2 || 'Please type a name here of at least 3 characters.']">
              </q-input>
              <q-toggle v-model="inotabot" label="I am not a bot. I promise."></q-toggle>
                <q-btn label="Search" type="submit" color="primary"></q-btn>
                <q-btn label="Reset" type="reset" color="primary" flat class="q-ml-sm"></q-btn>
            </q-form>
          </div>
          <div class="q-pa-md q-gutter-md">
            <q-virtual-scroll type="table" style="max-height: 70vh" :virtual-scroll-item-size="48" :virtual-scroll-sticky-size-start="48"
              :virtual-scroll-sticky-size-end="32" :items="playerNames">
              <template v-slot:before>
                <thead class="thead-sticky text-left">
                  <tr>
                    <th width="50%">Name</th>
                    <th>Last Seen</th>
                  </tr>
                </thead>
              </template>

              <template v-slot="{ item: row, index }">
                <tr :key="index">
                  <template v-if="row.id !== 0">
                    <td><router-link :to="`/player/${row.id}`" >{{ row.name }}</router-link></td>
                  </template>
                  <template v-else>
                    <td>{{ row.name }}</td>
                  </template>
                  <td>{{ row.lastseen }}</td>
                </tr>
              </template>
            </q-virtual-scroll>
          </div>
        </div>
      </div>
      <q-page-sticky expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <img src="/images/hero.png">
          <q-toolbar-title>
            Player Finder
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
    </q-page>
  </q-page-container>
</template>

<script>
import { ref } from 'vue';
import { getPlayerNames } from '../js/query';
import { mapState } from 'vuex';
import { Notify } from 'quasar';

export default {
  name: 'PlayerFinder',
  setup () {
    const playerName = ref(null);
    const inotabot = ref(false);
    const findingPlayers = ref(false);

    const resetForm = () => {
      inotabot.value = false;
      playerName.value = null;
    };

    const findPlayers = async () => {
      if (inotabot.value) {
        if (containsSpecialChars() === false) {
          findingPlayers.value = true;
          getPlayerNames(playerName.value);
        } else {
          Notify.create({ message: 'Special Character Detected. Only letters, numbers, and spaces allowed.', color: 'negative', position: 'top'});
        }
        inotabot.value = false;
      }
    };

    const containsSpecialChars = () => {
      // eslint-disable-next-line no-useless-escape
      const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
      return specialChars.test(playerName.value);
    };

    return {
      playersColumns: [
        { name: 'name', label: 'Name', field: 'name', align: 'left'},
        { name: 'lastseen', label: 'Last Seen', field: 'lastseen', align: 'left'},
      ],
      playerName,
      inotabot,
      findingPlayers,
      resetForm,
      findPlayers,
      containsSpecialChars
    }
  },
  computed: {
    ...mapState(['playerNames']),
  },
}
</script>
